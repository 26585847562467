<template>
    <div>
        <Header :id="id"></Header>
         <!-- 轮播图 -->
        <a-carousel autoplay class="bannerimg">
          <div v-for="(item,index) in banners" :key="index">
            <img :src="item.mediaUrl" alt="">
          </div>
        </a-carousel>
        <!-- 关于展会 -->
        <div class="aboute">
            <div class="aboute2">
                <div class="labout" @click="showModal">
                  <div class="atimg">
                    <img src="@/assets/images/home.png" alt="">
                  </div>
                  <div class="attxt">
                     {{ $t('register.buyer') }}
                     <div class="aximg">
                       <img src="@/assets/images/redright.png" alt="">
                     </div>
                  </div>
                </div>
                <div class="rabout" @click="showModal">
                  <div class="ratimg">
                    <img src="@/assets/images/twoface.png" alt="">
                  </div>
                   <div class="rattxt">
                      {{ $t('register.exhibitor') }} 
                     <div class="raximg">
                       <img src="@/assets/images/blueright.png" alt="">
                     </div>
                  </div>
                </div>
              <a-modal v-model="visible"  width="1060px">
                <template slot="footer">
                  <a-button key="submit"  :loading="loading" @click="handleOk">
                    SUBMIT
                  </a-button>
                </template>
                <div class="mtitle">{{ $t('registration') }}</div>
                <div class="minfo">
                  <div class="minfo1">
                    <div class="mleft1">{{ $t('company.name') }}</div>
                    <div class="mright1">{{registInfo.companyName}}</div>
                  </div>
                  <div class="minfo1">
                    <div class="mleft1">{{ $t('area') }}</div>
                    <div class="mright1">{{registInfo.area}}</div>
                  </div>
                  <div class="minfo2">
                    <div class="mitem1">{{ $t('address') }}</div>
                    <div class="mitem2">{{registInfo.address}}</div>
                    <div class="mitem3">{{ $t('postalcode') }}</div>
                    <div class="mitem4">{{registInfo.postCode}}</div>
                  </div>
                  <div class="minfo2">
                    <div class="mitem1">{{ $t('contact') }}</div>
                    <div class="mitem2">{{registInfo.concat}}</div>
                    <div class="mitem3">{{ $t('title') }}</div>
                    <div class="mitem4">{{registInfo.title}}</div>
                  </div>
                   <div class="minfo2">
                    <div class="mitem1">{{ $t('mobile.number') }}</div>
                    <div class="mitem2">{{registInfo.mobile}}</div>
                    <div class="mitem3">{{ $t('email') }}</div>
                    <div class="mitem4">{{registInfo.email}}</div>
                  </div>
                  <div class="minfo3">
                    <div class="minfo4">{{ $t('contact') }}</div>
                    <div class="minfo5"><input type="text" :placeholder="$t('please.enter')"  v-model="concat" /></div>
                    <div class="minfo6">{{ $t('telephone') }}</div>
                    <div class="minfo7"><input type="text" :placeholder="$t('please.enter')" v-model="telephone" /></div>
                  </div>
                  <div class="minfo3">
                    <div class="minfo4">{{$t('weChat')}}</div>
                    <div class="minfo5"><input type="text" :placeholder="$t('please.enter')" v-model="wechat" /></div>
                    <div class="minfo6">{{$t('whatsApp')}}</div>
                    <div class="minfo7"><input type="text" :placeholder="$t('please.enter')" v-model="whatsapp" /></div>
                  </div>
                  <div class="minfo3">
                    <div class="minfo4">{{$t('Industry.type')}}</div>
                    <div class="minfo8">
                    <a-checkbox-group v-model="categoryIds">
                        <a-checkbox :value="item.categoryId" v-for="(item,index) in categoryList" :key="index">
                          {{item.categoryName}}
                        </a-checkbox>
                        <a-checkbox value="0" @click="checked()">{{ $t('other') }}</a-checkbox>
                      </a-checkbox-group>
                    </div>
                  </div>
                  <div class="minfo3" v-show="checktag">
                    <div class="minfo4">{{$t('othercate')}}</div>
                    <div class="minfo5"><input type="text" :placeholder="$t('please.enter')"  v-model="other" /></div>
                  </div>
                </div>
              </a-modal>
            </div>
            <div class="about3" v-if="exhibition.exhibitionVideo">
               <video :src="exhibition.exhibitionVideo" controls="controls" class="video" style="width:100%"></video>
            </div>
            <div class="about3">
              <div class="abtop"> {{ $t('about.exhibition') }}
                <div class="absee">
                  <router-link :to="{ name: 'About', params: { id: id } }">
                    {{ $t('see.more') }} <a-icon type="right" class="hr"/>
                  </router-link>
                </div>
              </div>
              <div class="abtwo">
                <div class="abtwol">
                  <img :src="exhibition.exhibitionThumb" alt="">
                </div>
                <div class="abtwor">
                  <div class="twor1">{{exhibition.exhibitionTitle}}</div>
                  <div class="twor2" v-html="exhibition.exhibitionBrief"></div>
                  <div class="twor3">
                    <router-link :to="{ name: 'Exhibitors', params: { id: id } }">
                       {{ $t('enter.hall') }}
                    </router-link>
                    </div>
                </div>
              </div>
            </div>
        </div>
        <!-- 特色展会 -->
        <div class="featu" v-if="features!=''">
          <div class="featu2">
            <div class="fea1">{{ $t('Featured.exhibits') }}</div>
            <div class="fea2">
              <div class="fea3" v-for="(item,index) in features" :key="index">
                <div class="feaimg">
                  <img :src="item.featureImage" alt="">
                </div>
                <div class="featxt">
                  {{item.featureName}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 产品 -->
        <div class="product" v-if="products!=''">
          <div class="prod">
            <div class="prod1">{{ $t('products') }}</div>
            <div class="prod2">
              <div class="prod3" v-for="(item,index) in products" :key="index">
                <div class="prodimg">
                  <router-link  :to="{ name: 'Productdetail', params: { id: item.companyId, productId: item.productId, exhibitionId: id }}">
                    <img :src="item.thumb" alt="">
                  </router-link>
                </div>
                <div class="protxt">
                  <router-link  :to="{ name: 'Productdetail', params: { id: item.companyId, productId: item.productId, exhibitionId: id }}">{{item.productName}}</router-link>
                </div>
                <div class="protxt1">{{ $t('MOQ') }}: {{item.minOrder}}</div>
                <div class="protxt2">{{item.companyName}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 展会 -->
        <div class="exbit" v-if="exhibitiors!=''">
          <div class="exbit2">
            <div class="extitel">{{ $t('exhibitors') }}</div>
            <div class="exbit3">
              <div class="exbit4" v-for="(item,index) in exhibitiors" :key="index">
                <div class="exbit5">
                  <div class="exbit6">
                    <img :src="item.logo" alt="">
                  </div>
                  <div class="exbit7">
                    <router-link  :to="{ name: 'Supplier', params: { exhibitionId: id, id: item.companyId }}">
                      <div class="exbit8">{{item.companyName}}</div>
                    </router-link>
                    <div class="exbit9">
                      <div class="ex1">
                        <img src="@/assets/images/e2.png" alt="">
                      </div>
                      <div class="ex2">{{ $t('audited.supplier') }}</div>
                      <div class="ex3">
                        <img src="@/assets/images/e2.png" alt="">
                      </div>
                      <div class="ex2">{{ $t('chat.exhibitor') }}</div>
                    </div>
                  </div>
                </div>
                <a-carousel class="exbimg" autoplay>
                  <div v-for="k in Math.ceil(item.products.length / 3)" :key="k" class="exbimg2">
                      <div v-for="(vo, key) in item.products.slice((k - 1) * 3, k * 3)" :key="key" class="exbimg3">
                        <router-link  :to="{ name: 'Supplier', params: { exhibitionId: id, id: item.companyId }}">
                          <img :src="vo.thumb">
                        </router-link>
                        <div class="exbtxt">
                          <router-link  :to="{ name: 'Supplier', params: { exhibitionId: id, id: item.companyId }}">
                            {{vo.productName}}
                          </router-link>
                        </div>
                      </div>
                  </div>
                </a-carousel>
              </div>
            </div>
          </div>
        </div>
        <!-- 主办方和组织者 -->
        <div class="spon">
          <div class="org">
            <div class="orgtit" v-if="zhucoorgs!=''">{{ $t('sponsor') }}</div>
            <div class="orgimg" v-if="zhucoorgs!=''">
              <div class="orgimg1" v-for="(item,index) in zhucoorgs" :key="index">
                <img :src="item.cooperationLogo" alt="">
              </div>
            </div>
            <div class="orgtit" v-if="xiecoorgs!=''">{{ $t('organiser') }}</div>
            <div class="orgimg2"  v-if="xiecoorgs!=''">
              <div class="orgimg3" v-for="(item,index) in xiecoorgs" :key="index">
                <img :src="item.cooperationLogo" alt="">
              </div>
            </div>
            <div class="orgtit" v-if="chengcoorgs!=''">{{ $t('Undertaker') }}</div>
            <div class="orgimg2"  v-if="chengcoorgs!=''">
              <div class="orgimg3" v-for="(item,index) in chengcoorgs" :key="index">
                <img :src="item.cooperationLogo" alt="">
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
    </div>
</template> 

<script>
// import "@/assets/css/exindex.less";
import Header from "./components/Header";
import Footer from "../components/Footer";

const baseUrl =
  'https://raw.githubusercontent.com/vueComponent/ant-design-vue/master/components/vc-slick/assets/img/react-slick/';
  
export default {
  name: "Exhibi",
  components: {
    Footer,
    Header,
  },
   data() {
    return {
      baseUrl,
      props: [
        'https://seopic.699pic.com/photo/50173/9010.jpg_wh1200.jpg',
        'https://seopic.699pic.com/photo/50087/4783.jpg_wh1200.jpg',
        'https://seopic.699pic.com/photo/50087/4788.jpg_wh1200.jpg',
        'https://seopic.699pic.com/photo/50173/9010.jpg_wh1200.jpg',
        'https://seopic.699pic.com/photo/50087/4783.jpg_wh1200.jpg',
        'https://seopic.699pic.com/photo/50087/4788.jpg_wh1200.jpg',
        'https://seopic.699pic.com/photo/50173/9010.jpg_wh1200.jpg',
        'https://seopic.699pic.com/photo/50087/4783.jpg_wh1200.jpg',
        'https://seopic.699pic.com/photo/50087/4788.jpg_wh1200.jpg',
      ],
      loading: false,
      visible: false,
      id: this.$route.params.id,
      // exhibitionId: this.$route.params.exhibitionId,
      banners: [],
      exhibition: [],
      registInfo: [],
      products: [],
      exhibitiors: [],
      features: [],
      xiecoorgs: [],
      zhucoorgs: [],
      telephone: '',
      concat: '',
      wechat: '',
      whatsapp: '',
      categoryList: [],
      categoryIds: [],
      checktag: false,
      other: '',
      chengcoorgs: []
    };
  },
  mounted () {
    console.log(this.$route)
    this.getDetail()
    this.getList()
    this.getCategoryList()
  },
  methods: {
    getImgUrl(i) {
      return `${baseUrl}abstract0${i + 1}.jpg`;
    },
    showModal() {
      this.getRegistInfo()
    },
    handleOk(e) {
      const categoryIds2 = []
      this.categoryIds.forEach(item =>{
        if (item  != 0) {
          categoryIds2.push(
            {'categoryId': item, 'isOther': 0}
          )
        } else {
          categoryIds2.push(
            {'categoryId': 0, 'isOther': 1, 'desc': this.other}
          )
        }
      })
      this.loading = true;
      const params = {
        exhibitionId: this.id,
        telephone: this.telephone,
        concat: this.concat,
        wechat: this.wechat,
        whatsapp: this.whatsapp,
        categoryIds: categoryIds2
      }
      this.$common.handlePost('/exhibition/register', params).then(data => {
        if (data.code === 200) {
            this.$layer.alert("报名成功");
        } else {
            this.$layer.alert(data.msg);
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
      setTimeout(() => {
        this.visible = false;
        this.loading = false;
      }, 3000);
    },
    handleCancel(e) {
      this.visible = false;
    },
    // 获取展会详情
    getDetail () {
       this.$common.fetchList('/exhibition/detail/'+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.exhibition = result
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    // 展会报名信息展示
    getRegistInfo () {
       this.$common.fetchList('/exhibition/registInfo/'+this.id, {}).then(data => {
        if (data.code === 200) {
          this.visible = true;
          const result = data.data
          this.registInfo = result
        } else {
          this.$layer.alert(data.msg);
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    // 展会官网首页
    getList () { 
       this.$common.fetchList('/exhibition/index?exhibitionId='+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          for(const [k, v] of Object.entries(result)) {
            this[k] = v
          }
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
     //供应商包含的行业
    getCategoryList () {
       this.$common.fetchList('/company/category/lists', {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.categoryList = result
          console.log(this.categoryList)
        } 
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    checked() {
      this.checktag = !this.checktag
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/exindex.less';
</style>
